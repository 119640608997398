//==============================================================================
// COLOR VARIABLES
//==============================================================================

//-----------------------------
// CUSTOM COLORS
//-----------------------------




//-----------------------------
// FIXED COLORS
//-----------------------------

$c-brand-primary: #273B89;
$c-brand-secondary: #F36F21;

$c-white: #FFF;
$c-black: #000;

$c-gray--dark: #58585b;
$c-gray--normal: #969696;
$c-gray--normal2: #CCC;
$c-gray--light: #F0F0F0;

$c-blue--light: #f0f1f7;

$c-status-light: #e6efc2;
$c-status: #049e19;
$c-notice-light: #e5edf8;
$c-notice: #0086c9;
$c-warning-light: #fff6bf;
$c-warning: #ee7f00;
$c-error-light: #fbe3e4;
$c-error: #b33630;


//-----------------------------
// COMPONENTS COLORS
//-----------------------------

// Sitewide
$body-bg: $c-white;

// Mobile toggle
$c-mt-bar-bg: $c-brand-primary;
$c-mt-bar-hov-bg: $c-brand-secondary;
$c-mt-bar-toggled-bg: $c-brand-secondary;
$c-mt-btn-bg: transparent;
$c-mt-btn-hov-bg: transparent;
$c-mt-btn-border: transparent;
$c-mt-btn-hov-border: transparent;
$c-mt-span-txt: $c-brand-primary;
$c-mt-span-hov-txt: $c-brand-secondary;

// Content colors
$c-base-txt: $c-black;
$c-base-link: $c-brand-primary;
$c-base-link-hov: $c-gray--dark;

// Borders
$c-base-border-color: $c-brand-primary;

// Forms
$c-select-arrow: $c-brand-secondary;
$c-fi-bg: $c-white;
$c-fi-border-color: $c-brand-secondary;
$c-fi-border-focus: $c-brand-primary;
$c-description-txt: $c-gray--normal;
$c-fs-border-color: $c-gray--normal;
$c-fs-bg: $c-gray--light;
$c-lbl-color: $c-brand-primary;

// Tables
$c-base-table-border-color: $c-base-border-color;

// Shadows
$c-shadow: $c-black;
$c-shadow-rgba: rgba($c-shadow, 0.06);
$c-shadow-focus: adjust-color($c-shadow-rgba, $lightness: -5%, $alpha: -0.3);

// Button colors
$c-btn-default: (
  txt: $c-white,
  icon: $c-white,
  bg: $c-brand-primary,
  border: $c-brand-primary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-gray--dark,
  border-hov: $c-gray--dark,
);

$c-btn-primary: (
  txt: $c-white,
  icon: $c-white,
  bg: $c-brand-primary,
  border: $c-brand-primary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-gray--dark,
  border-hov: $c-gray--dark,
);

$c-btn-secondary: (
  txt: $c-white,
  icon: $c-white,
  bg: $c-brand-secondary,
  border: $c-brand-secondary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-gray--dark,
  border-hov: $c-gray--dark,
);

$c-btn-secondary--white: (
  txt: $c-brand-secondary,
  icon: $c-brand-secondary,
  bg: $c-white,
  border: $c-brand-secondary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-brand-secondary,
  border-hov: $c-brand-secondary,
);

$c-btn-white: (
  txt: $c-brand-primary,
  icon: $c-brand-primary,
  bg: $c-white,
  border: $c-white,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-gray--dark,
  border-hov: $c-gray--dark,
);

// Message colors
$c-message: (
  status-txt: $c-gray--dark,
  status-bg: $c-status-light,
  status-border: $c-status,
  notice-txt: $c-gray--dark,
  notice-bg: $c-notice-light,
  notice-border: $c-notice,
  warning-txt: $c-gray--dark,
  warning-bg: $c-warning-light,
  warning-border: $c-warning,
  error-txt: $c-gray--dark,
  error-bg: $c-error-light,
  error-border: $c-error,
);

// Pager
$c-pager: (
  btn-txt: $c-brand-primary,
  btn-bg: transparent,
  btn-txt-hov: $c-gray--dark,
  btn-bg-hov: $c-gray--light,
  btn-txt-active: $c-white,
  btn-bg-active: $c-gray--dark,
);

// Horizontal tabs
$c-tabs-border: $c-base-border-color;
$c-tabs-btn-txt: $c-brand-primary;
$c-tabs-btn-border: $c-brand-primary;
$c-tabs-btn-txt-active: $c-brand-primary;
$c-tabs-btn-border-active: $c-brand-primary;

// Breadcrumbs
$c-bc-txt: $c-white;

// Local tasks
$c-lt-menu-bg-desk: #f5f5f5;
$c-lt-trigger-bg: #e6e7e8;
$c-lt-trigger-txt-hov: $c-black;
$c-lt-trigger-bg-hov: #e6e7e8;
$c-lt-tab-bg: #e6e7e8;
$c-lt-tab-link: $c-base-txt;
$c-lt-prim-tab-bg: $c-gray--dark;
$c-lt-prim-tab-txt-active: $c-brand-secondary;

$c-xs-tab-bg-hov: $c-gray--dark;
$c-xs-tab-txt-hov: $c-white;
$c-xs-active-tab-bg: $c-gray--light;
$c-xs-active-tab-txt: $c-brand-primary;

$c-sm-tab-bg-hov: $c-gray--dark;
$c-sm-tab-txt-hov: $c-white;

// Cookie Compliance Bar colors
$c-eucc-bg: $c-gray--dark;
$c-eucc-txt: $c-white;

$c-btn-eucc: (
  txt: $c-brand-primary,
  icon: $c-brand-primary,
  bg: transparent,
  border: $c-brand-primary,
  txt-hov: $c-white,
  icon-hov: $c-white,
  bg-hov: $c-brand-primary,
  border-hov: $c-brand-primary,
);

$c-btn-eucc-link: (
  txt: $c-white,
  icon: $c-white,
  bg: transparent,
  border: transparent,
  txt-hov: $c-brand-primary,
  icon-hov: $c-brand-primary,
  bg-hov: transparent,
  border-hov: transparent,
);

// System pages colors

$c-sys-page-bg: #F0F0F0;
$c-sys-wrap-bg: $c-white;
$c-sys-frm-head-bg: #F8F8F8;

// Maintenance cartoon colors
$c-sys-mpc: $c-brand-primary;
$c-sys-mpc-light: darken($c-brand-primary, 20%);
$c-sys-mpc-dark: lighten($c-brand-primary, 40%);
