@charset "UTF-8"; // Make sure the charset is set appropriately.

// -----------------------------------------------------------------------------
// @file
// This file is for the maintenance page theming.
// -----------------------------------------------------------------------------

@import "https://unpkg.com/orejime@2.1.1/dist/orejime.css";

// Import all fonts.
@import 'fonts';

// Import global variables.
@import './_sass-essentials/essentials.scss';

// Import your pages here.
@import "_scss-partials/general/cookie_orejime";
