@import "https://unpkg.com/orejime@2.1.1/dist/orejime.css";
#orejime button:hover, #orejime button:focus {
  text-decoration: none;
}
#orejime button:hover,
#orejime button svg:hover {
  cursor: pointer;
}

.orejime-Button--save,
.orejime-Button--accept {
  background-color: #F36F21;
}
.orejime-Button--save:hover, .orejime-Button--save:focus,
.orejime-Button--accept:hover,
.orejime-Button--accept:focus {
  background-color: #f68e51;
}

.orejime-Button--decline {
  background-color: #58585b;
}
.orejime-Button--decline:hover, .orejime-Button--decline:focus {
  background-color: #717175;
}

.orejime-Button--info {
  background-color: #273B89;
}
.orejime-Button--info:hover, .orejime-Button--info:focus {
  background-color: #324cb1;
}

.orejime-Modal-closeButton .orejime-CloseIcon:hover {
  stroke: #F36F21;
}

.orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider {
  background-color: #273B89;
}

.orejime-CategorizedAppList-apps {
  margin-left: 0.5em;
}

.orejime-AppItem-switchLabel {
  display: none;
  font-size: 0.65em;
  top: 36px;
}

.orejime-Modal-privacyPolicyLink:hover, .orejime-Modal-privacyPolicyLink:focus {
  color: #F36F21;
}

.orejime-Modal-poweredByLink {
  display: none !important;
}

.orejime-AppList-item {
  padding-left: 70px;
}

.orejime-Notice .orejime-Notice-text {
  padding-bottom: 1em;
}
@media screen and (min-width: 768px) {
  .orejime-Notice {
    max-width: 50ch;
    margin: 0 1em 1em auto;
  }
}