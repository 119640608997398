//==============================================================================
// ORIJIME
//==============================================================================

// Buttons styles.
#orejime {
  button {
    &:hover,
    &:focus {
      text-decoration: none;
    }

    &:hover,
    svg:hover {
      cursor: pointer;
    }
  }
}

.orejime-Button--save,
.orejime-Button--accept {
  background-color: $c-brand-secondary;

  &:hover,
  &:focus {
    background-color: lighten($c-brand-secondary, 10%);
  }
}

.orejime-Button--decline {
  background-color: $c-gray--dark;

  &:hover,
  &:focus {
    background-color: lighten($c-gray--dark, 10%);
  }
}

.orejime-Button--info {
  background-color: $c-brand-primary;

  &:hover,
  &:focus {
    background-color: lighten($c-brand-primary, 10%);
  }
}

.orejime-Modal-closeButton {
  .orejime-CloseIcon {
    &:hover {
      stroke: $c-brand-secondary;
    }
  }
}

// App list styles.

.orejime-AppItem-input:checked + .orejime-AppItem-label .orejime-AppItem-slider {
  background-color: $c-brand-primary;
}

.orejime-CategorizedAppList-apps {
  margin-left: 0.5em;
}

.orejime-AppItem-switchLabel {
  display: none;
  font-size: 0.65em;
  top: 36px;
}

.orejime-Modal-privacyPolicyLink {
  &:hover,
  &:focus {
    color: $c-brand-secondary;
  }
}

.orejime-Modal-poweredByLink {
  display: none !important;
}

.orejime-AppList-item {
  padding-left: 70px;
}

// Extra app popup styles.

.orejime-Notice {
  .orejime-Notice-text {
    padding-bottom: 1em;
  }

  @include mq($bp-sm-min) {
    max-width: 50ch;
    margin: 0 1em 1em auto;
  }
}
